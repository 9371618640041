<template>
  <div>
    <b-table
      small
      :fields="fields"
      :items="items"
      responsive="sm"
    >
      <!-- A virtual column -->
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <!-- A virtual composite column -->
      <template #cell(valor)="data">
        {{ moedaBR(data.value) }}
      </template>

    </b-table>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'

export default {
  components: {
    BTable,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        'index',
        { key: 'nome', label: 'nome' },
        { key: 'valor', label: 'valor' },
      ],
    }
  },
  methods: {
    moedaBR(valor) {
      const numero = parseFloat(valor)
      const moeda = numero.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })
      return moeda
    },
  },
}
</script>

<template>
  <div>
    <b-row>
      <b-col>
        <b-button
          class="mb-1"
          variant="primary"
          @click="openModalFiltro"
        >
          <span class="align-middle">Novo Filtro</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col
              cols="21"
              xl="9"
              class="d-flex justify-content-between flex-column"
            >
              <div class="align-items-center">
                <b-row>
                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-warning"
                        rounded
                      >
                        <feather-icon
                          icon="TrendingUpIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ data.parcelas }}
                        </h5>
                        <small>Total</small>
                      </div>
                    </div>
                  </b-col>

                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-warning"
                        rounded
                      >
                        <feather-icon
                          icon="TrendingUpIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ data.total_reparo }}
                        </h5>
                        <small>Reparo</small>
                      </div>
                    </div>
                  </b-col>

                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-warning"
                        rounded
                      >
                        <feather-icon
                          icon="TrendingUpIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ data.total_troca }}
                        </h5>
                        <small>Troca</small>
                      </div>
                    </div>
                  </b-col>

                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-warning"
                        rounded
                      >
                        <feather-icon
                          icon="TrendingUpIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ data.total_reincidencia }}
                        </h5>
                        <small>Reincidência</small>
                      </div>
                    </div>
                  </b-col>

                </b-row>

                <b-row class="mt-2">
                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-primary"
                        rounded
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ moedaBR(data.cmr) }}
                        </h5>
                        <small>CMR</small>
                      </div>
                    </div>
                  </b-col>

                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-primary"
                        rounded
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ moedaBR(data.cmg) }}
                        </h5>
                        <small>CMG</small>
                      </div>
                    </div>
                  </b-col>

                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-primary"
                        rounded
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ data.tat.toFixed(2) }}
                        </h5>
                        <small>TAT</small>
                      </div>
                    </div>
                  </b-col>

                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-primary"
                        rounded
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ data.pt.toFixed(2) }}%
                        </h5>
                        <small>PT</small>
                      </div>
                    </div>
                  </b-col>
                </b-row>

                <b-row class="mt-1">
                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-warning"
                        rounded
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ data.lmi.toFixed(2) }}%
                        </h5>
                        <small>LMI</small>
                      </div>
                    </div>
                  </b-col>

                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-info"
                        rounded
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ moedaBR(data.total_seguradora) }}
                        </h5>
                        <small>Aprovado Seguradora</small>
                      </div>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-danger"
                        rounded
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ moedaBR(data.total_pago) }}
                        </h5>
                        <small>Gasto</small>
                      </div>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-success"
                        rounded
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ moedaBR(data.total_lucro) }}
                        </h5>
                        <small>Lucro</small>
                      </div>
                    </div>
                  </b-col>
                </b-row>

                <b-row class="mt-2">
                  <b-col>
                    <b-button
                      class="mb-1"
                      variant="outline-warning"
                      :disabled="!items.length > 0"
                      @click="exportFile"
                    >
                      <span class="align-middle">Exportar</span>
                    </b-button>
                    <b-button
                      class="mb-1 ml-1"
                      variant="outline-primary"
                      @click="openModalRank"
                    >
                      <span class="align-middle">Analytcs</span>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-col>

            <b-col
              cols="21"
              xl="3"
            >
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="UserIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Atendentes</span>
                  </th>
                  <td class="pb-50">
                    <b-badge variant="primary">
                      {{ filtro.atendente ? filtro.atendente.nome : 'Todos' }}
                    </b-badge>
                  </td>
                </tr>

                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Data Inicial</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ filtro.data_inicial }}
                  </td>
                </tr>

                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Data Final</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ filtro.data_final }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="CreditCardIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Tipo de atedimento</span>
                  </th>
                  <td class="pb-50">
                    <b-badge variant="primary">
                      <div
                        v-for="name in filtro.tipo_atendimento"
                        :key="name.tipo_atendimento"
                      >
                        {{ filtro.tipo_atendimento ? name.tipo_atendimento: '' }}
                      </div>
                    </b-badge>
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-overlay
            variant="white"
            :show="load"
            spinner-variant="primary"
            blur="0"
            opacity=".75"
            rounded="sm"
          >
            <b-row class="mb-1">
              <b-col
                md="3"
              >
                <label for="">Filtro</label>
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Digite para pesquisar"
                />
              </b-col>
            </b-row>
            <b-table
              hover
              striped
              responsive
              fixed
              :fields="fields"
              :items="items"
              :per-page="itemPerPage"
              :current-page="currentPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{
                    width:
                      field.key === 'origem'
                        ? '400px'
                        : '180px',
                  }"
                >
              </template>
              <template #cell(id)="data">
                <a :href="'/os/view/' + data.item.id">{{ data.item.id }}</a>
              </template>
              <template #cell(valor_total_seguradora)="data">
                {{ moedaBR(data.item.valor_total_seguradora) }}
              </template>
              <template #cell(valor_total_aprovado)="data">
                {{ moedaBR(data.item.valor_total_aprovado) }}
              </template>
              <template #cell(lucro)="data">
                {{ moedaBR(parseFloat(data.item.valor_total_seguradora) - parseFloat(data.item.valor_total_aprovado)) }}
              </template>
              <template #cell(produto_valor)="data">
                {{ moedaBR(data.item.produto_valor) }}
              </template>
              <template #cell(data_fechamento)="data">
                {{ americaDate(data.item.data_fechamento) }}
              </template>

            </b-table>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="itemPerPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>

    <b-modal
      id="filtro"
      ref="filtro"
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
    >
      <b-row>
        <b-col>
          <label for="">Data Fechamento Início</label>
          <b-form-input
            id="fechamento-inicio"
            v-model="filtro.data_inicial"
            locale="pt"
            type="date"
          />
        </b-col>
        <b-col>
          <label for="">Data Fechamento Final</label>
          <b-form-input
            id="fechamento-final"
            v-model="filtro.data_final"
            locale="pt"
            type="date"
          />
        </b-col>
      </b-row>
      <b-row
        v-if="!isAtendente"
        class="mb-1"
      >
        <b-col md="12">
          <label for="">Selecione o atendente</label>
          <v-select
            v-model="filtro.atendente"
            :options="atendentes"
            label="nome"
            multiple
          />
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col md="6">
          <label for="">Tipo de Atendimento</label>
          <v-select
            v-model="filtro.tipo_atendimento"
            :options="optionsAtendimento"
            multiple
            label="formapagamento"
          />
        </b-col>
        <b-col md="6">
          <label for="">Seguradora</label>
          <v-select
            v-model="filtro.seguradora"
            :options="seguradora"
            label="nome"
            multiple
          />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col md="6">
          <label for="">Linha</label>
          <v-select
            v-model="filtro.linha"
            :options="linha"
            label="nome"
            multiple
          />
        </b-col>
        <b-col md="6">
          <label for="">Marca</label>
          <v-select
            v-model="filtro.marca"
            :options="marca"
            label="nome"
            multiple
          />
        </b-col>
      </b-row>

      <template #modal-footer="{cancel}">
        <div class="w-100">
          <b-button
            variant="outline-danger"
            class="float-right ml-1"
            @click="cancel()"
          >
            Cancelar
          </b-button>
          <b-button
            variant="warning"
            class="float-right"
            :disabled="validateForm"
            @click="getFilter(), load = true"
          >
            Filtrar
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- basic modal -->
    <b-modal
      id="ranking"
      ref="ranking"
      title="Ranking"
      ok-only
      ok-title="Accept"
      size="lg"
    >
      <b-tabs>
        <b-tab active>
          <template #title>
            <feather-icon icon="UsersIcon" />
            <span>Atendente</span>
          </template>
          <ranking-atendente :items="rankAtendimento" />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="AwardIcon" />
            <span>Seguradora</span>
          </template>
          <ranking-atendente :items="rankSeguradora" />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="ShoppingBagIcon" />
            <span>Revenda</span>
          </template>
          <ranking-atendente :items="rankRevenda" />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="AlignCenterIcon" />
            <span>Linha</span>
          </template>
          <ranking-atendente :items="rankLinha" />
          <b-card-text />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="MapIcon" />
            <span>Estado</span>
          </template>
          <ranking-atendente :items="rankEstado" />
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BFormInput,
  BButton,
  BCard,
  BAvatar,
  BBadge,
  BTable,
  BPagination,
  BOverlay,
  BTabs,
  BTab,
  BCardText,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _ from 'lodash'

// eslint-disable-next-line no-unused-vars
import { saveAs } from 'file-saver'
import api from '@/../axios-auth'
import RankingAtendente from './RankingAtendente.vue'
// eslint-disable-next-line no-unused-vars
const ExcelJS = require('exceljs')

export default {
  components: {
    BRow,
    BCol,
    BModal,
    vSelect,
    BFormInput,
    BButton,
    BCard,
    BAvatar,
    BBadge,
    BTable,
    BPagination,
    BOverlay,
    BTabs,
    BTab,
    BCardText,
    RankingAtendente,
  },
  data() {
    return {
      empresaID: null,
      atendentes: [],
      seguradora: [],
      linha: [],
      marca: [],
      items: [],
      formaPagamento: [
        { formapagamento: 'Boleto', key: 'boleto_pix' },
        { formapagamento: 'Cartão Voz', key: 'cartao_voz' },
        { formapagamento: 'Cartão Credor', key: 'cartao_credor' },
        { formapagamento: 'Pix', key: 'pix' },
      ],
      optionsAtendimento: [
        'NORMAL',
        'PRIORITÁRIO',
        'EMERGENCIAL',
        'DANOS ELÉTRICOS',
        'ASSISTÊNCIA FUNERAL',
        'REINCIDÊNCIA',
      ],
      tipoPagamento: [
        { tipopagamento: 'Conta Voz', key: 'Conta Voz' },
        { tipopagamento: 'Conta Credor', key: 'Conta Credor' },
        { tipopagamento: 'Acordo Voz - pgt na instituição', key: null },
        { tipopagamento: 'Baixa administrativa', key: 'Baixa Administrativa' },
      ],
      filter: null,
      filterOn: [],
      itemPerPage: 20,
      currentPage: 1,
      totalRows: 1,
      data: {
        total_reincidencia: 0,
        parcelas: 0,
        realizado: 0,
        tat: 0,
        cmr: 0,
        cmg: 0,
        pt: 0,
        rec: 0,
        lmi: 0,
        total_troca: 0,
        total_reparo: 0,
        total_pago: 0,
        total_lucro: 0,
        total_seguradora: 0,
      },
      filtro: {
        atendente: [],
        seguradora: [],
        linha: [],
        marca: [],
        tipo_atendimento: [],
        tipo_servico: [],
        data_inicial: '',
        data_final: '',
      },
      rankAtendimento: [],
      rankSeguradora: [],
      rankLinha: [],
      rankRevenda: [],
      rankEstado: [],
      filterInput: '',
      fields: [
        { key: 'id', label: 'id' },
        { key: 'status', label: 'status' },
        { key: 'tipo_atendimento', label: 'Tipo Atendimento' },
        { key: 'atendente', label: 'atendente' },
        { key: 'seguradora', label: 'seguradora' },
        { key: 'sinistro', label: 'sinistro' },
        { key: 'cliente', label: 'cliente' },
        { key: 'produto_valor', label: 'IS' },
        { key: 'valor_total_seguradora', label: 'Vl Aprov Seguradora' },
        { key: 'valor_total_aprovado', label: 'gasto' },
        { key: 'lucro', label: 'Lucro' },
        { key: 'data_fechamento', label: 'D. Fechamento', class: 'text-center' },
        { key: 'linha', label: 'linha', class: 'text-center' },
        { key: 'marca', label: 'marca', class: 'text-center' },
        { key: 'revenda', label: 'revenda', class: 'text-center' },
        { key: 'cidade_nome', label: 'cidade', class: 'text-center' },
        { key: 'estado_uf', label: 'estado', class: 'text-center' },
      ],
      load: false,
      isAtendente: false,
      atendenteId: null,
    }
  },
  computed: {
    validateForm() {
      const { data_inicial: dataInicial, data_final: dataFinal } = this.filtro
      const checkDataInicial = dataInicial ? dataInicial.length === 10 : null
      const checkDataFinal = dataFinal ? dataFinal.length === 10 : null
      if (checkDataInicial && checkDataFinal) {
        return false
      }
      return true
    },
  },
  created() {
    this.checkIsAtendente()

    this.empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
    this.filtro.data_inicial = this.dataAtual()
    this.filtro.data_final = this.dataAtual()
    this.getAtendentes()
    this.getSeguradoras()
    this.getLinhas()
    this.getMarcas()
    this.getFilter()
  },
  mounted() {
  },
  methods: {
    checkIsAtendente() {
      this.isAtendente = false
      const userData = JSON.parse(localStorage.userData)

      if (userData.funcao === 'ATENDENTE') {
        this.isAtendente = true
        this.atendenteId = userData.id
      }
    },
    moedaBR(valor) {
      const numero = parseFloat(valor)
      const moeda = numero.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })
      return moeda
    },
    applyFilter() {
      const filterFirstName = this.filter.toLowerCase().trim().split(' ')[0]
      const filteredNames = this.items.filter(name => {
        const firstName = name.cliente.split(' ')[0]
        return firstName.toLowerCase().indexOf(filterFirstName) === 0
      })

      return filteredNames[0].cliente
    },
    diasVencidos(vencimentoOriginal, recebimento) {
      const dataVecimentoOriginal = vencimentoOriginal
      const dataVencimentoOriginaDaOp = recebimento
      const diferencaDeDias = new Date(dataVencimentoOriginaDaOp) - new Date(dataVecimentoOriginal)
      const diasVenc = diferencaDeDias / (1000 * 60 * 60 * 24)
      return diasVenc
    },
    async getAtendentes() {
      const atendentes = await api.get(`api/empresas/atendentes/${this.empresaID}`).then(result => {
        const { dados } = result.data
        return dados
      }).catch(() => [])
      const at = []
      atendentes.map(a => {
        at.push({
          id: a.id,
          nome: a.name,
        })
      })
      at.sort((a, b) => a.nome.localeCompare(b.nome)) // ordena os atendentes pelo nome em ordem alfabética
      this.atendentes = at
      return at
    },
    async getSeguradoras() {
      const seguradora = await api.get(`api/empresas/seguradoras/${this.empresaID}`).then(result => {
        const { dados } = result.data
        return dados
      }).catch(() => [])
      const at = []
      seguradora.map(a => {
        at.push({
          id: a.id,
          nome: a.nome,
        })
      })
      at.sort((a, b) => a.nome.localeCompare(b.nome)) // ordena as seguradoras pelo nome em ordem alfabética
      this.seguradora = at
      return at
    },
    async getLinhas() {
      const linhas = await api.get(`api/empresas/linhas/${this.empresaID}`).then(result => {
        const { dados } = result.data
        return dados
      }).catch(() => [])
      const at = []
      linhas.map(a => {
        at.push({
          id: a.id,
          nome: a.nome,
        })
      })
      at.sort((a, b) => a.nome.localeCompare(b.nome)) // ordena as seguradoras pelo nome em ordem alfabética
      this.linha = at
      return at
    },
    async getMarcas() {
      const marcas = await api.get(`api/empresas/marcas/${this.empresaID}`).then(result => {
        const { dados } = result.data
        return dados
      }).catch(() => [])
      const at = []
      marcas.map(a => {
        at.push({
          id: a.id,
          nome: a.nome,
        })
      })
      at.sort((a, b) => a.nome.localeCompare(b.nome)) // ordena as seguradoras pelo nome em ordem alfabética
      this.marca = at
      return at
    },
    async openModalFiltro() {
      this.filtro.data_inicial = ''
      this.filtro.data_final = ''
      this.$refs.filtro.show()
    },

    openModalRank() {
      this.$refs.ranking.show()
    },

    changeDataFormat(item, pos) {
      const splited = item.split('-')
      const newDate = `${splited[2]}/${splited[1]}/${splited[0]}`
      if (!this.filtro[pos] !== newDate) {
        this.filtro[pos] = newDate
      }
    },
    dataAtualFiltro() {
      const hoje = new Date()
      const dia = hoje.getDate().toString().padStart(2, '0')
      const mes = String(hoje.getMonth() + 1).padStart(2, '0')
      const ano = hoje.getFullYear()
      const dataAtual = `${dia}/${mes}/${ano}`
      this.filtro.data_inicial = dataAtual
      this.filtro.data_final = dataAtual
    },

    getFilter() {
      let body = {}
      body = {
        empresa_id: this.empresaID,
        filtros: {
          data_inicial: this.filtro.data_inicial,
          data_final: this.filtro.data_final,
          atendente: this.isAtendente ? [this.atendenteId] : undefined,
        },
      }
      if (this.filtro.atendente.length) {
        const atendentes = this.filtro.atendente.map(item => item.id)
        body.filtros.atendente = atendentes
      }
      if (this.filtro.seguradora.length) {
        const seguradora = this.filtro.seguradora.map(item => item.id)
        body.filtros.seguradora = seguradora
      }
      if (this.filtro.tipo_atendimento.length) {
        body.filtros.tipoAtendimento = this.filtro.tipo_atendimento
      }
      if (this.filtro.linha.length) {
        const linha = this.filtro.linha.map(item => item.id)
        body.filtros.linha = linha
      }
      if (this.filtro.marca.length) {
        const marca = this.filtro.marca.map(item => item.id)
        body.filtros.marca = marca
      }
      this.load = true
      api.post('api/ordem_servico/fechamento', body, {
        headers: {
          Accept: 'application/json',
        },
      }).then(async result => {
        this.load = false
        this.totalRows = result.data.dados.length
        this.items = result.data.dados
        this.setData()
        this.$refs.filtro.hide()
        const groupedByAtendente = _.groupBy(this.items, 'atendente_id')
        const groupedBySeguradora = _.groupBy(this.items, 'seguradora_id')
        const groupedByRevenda = _.groupBy(this.items, 'revenda_id')
        const groupedByLinha = _.groupBy(this.items, 'linha_id')
        const groupedByEstado = _.groupBy(this.items, 'estado_id')
        this.ranckPorAtendente(groupedByAtendente)
        this.ranckPorSeguradora(groupedBySeguradora)
        this.ranckPorLinha(groupedByLinha)
        this.ranckPorRevenda(groupedByRevenda)
        this.ranckPorEstado(groupedByEstado)
      })
    },
    ranckPorAtendente(groupedByAtendente) {
      const rankAtendente = []
      let at
      let qt
      let lucro = 0
      const atendenteIds = Object.keys(groupedByAtendente)
      for (let i = 0; i < atendenteIds.length; i++) {
        const atendenteId = atendenteIds[i]
        at = groupedByAtendente[atendenteId][0].atendente
        qt = groupedByAtendente[atendenteId].length
        // eslint-disable-next-line no-loop-func
        groupedByAtendente[atendenteId].map(item => {
          lucro = (parseFloat(item.valor_total_seguradora) - parseFloat(item.valor_total_aprovado)) + parseFloat(lucro)
        })
        rankAtendente.push({
          nome: at,
          quantidade: qt,
          valor: lucro,
        })
        lucro = 0
      }
      const sortedArray = _.orderBy(rankAtendente, 'valor', 'desc')
      this.rankAtendimento = sortedArray
    },
    ranckPorSeguradora(groupedBySeguradora) {
      const rankSeguradora = []
      let at
      let qt
      let lucro = 0
      const seguradoraIds = Object.keys(groupedBySeguradora)
      for (let i = 0; i < seguradoraIds.length; i++) {
        const seguradoraId = seguradoraIds[i]
        at = groupedBySeguradora[seguradoraId][0].seguradora
        qt = groupedBySeguradora[seguradoraId].length
        // eslint-disable-next-line no-loop-func
        groupedBySeguradora[seguradoraId].map(item => {
          lucro = (parseFloat(item.valor_total_seguradora) - parseFloat(item.valor_total_aprovado)) + parseFloat(lucro)
        })
        rankSeguradora.push({
          nome: at,
          quantidade: qt,
          valor: lucro,
        })
        lucro = 0
      }
      const sortedArray = _.orderBy(rankSeguradora, 'valor', 'desc')
      this.rankSeguradora = sortedArray
    },
    ranckPorLinha(groupedByLinha) {
      const rankLinha = []
      let at
      let qt
      let lucro = 0
      const linhaIds = Object.keys(groupedByLinha)
      for (let i = 0; i < linhaIds.length; i++) {
        const linhaId = linhaIds[i]
        at = groupedByLinha[linhaId][0].linha
        qt = groupedByLinha[linhaId].length
        // eslint-disable-next-line no-loop-func
        groupedByLinha[linhaId].map(item => {
          lucro = (parseFloat(item.valor_total_seguradora) - parseFloat(item.valor_total_aprovado)) + parseFloat(lucro)
        })
        rankLinha.push({
          nome: at,
          quantidade: qt,
          valor: lucro,
        })
        lucro = 0
      }
      const sortedArray = _.orderBy(rankLinha, 'valor', 'desc')
      this.rankLinha = sortedArray
    },
    ranckPorRevenda(groupedByRevenda) {
      const rankRevenda = []
      let at
      let qt
      let lucro = 0
      const revendaIds = Object.keys(groupedByRevenda)
      for (let i = 0; i < revendaIds.length; i++) {
        const revendaId = revendaIds[i]
        at = groupedByRevenda[revendaId][0].revenda
        qt = groupedByRevenda[revendaId].length
        // eslint-disable-next-line no-loop-func
        groupedByRevenda[revendaId].map(item => {
          lucro = (parseFloat(item.valor_total_seguradora) - parseFloat(item.valor_total_aprovado)) + parseFloat(lucro)
        })
        rankRevenda.push({
          nome: at,
          quantidade: qt,
          valor: lucro,
        })
        lucro = 0
      }
      const sortedArray = _.orderBy(rankRevenda, 'valor', 'desc')
      this.rankRevenda = sortedArray
    },
    ranckPorEstado(groupedByEstado) {
      const rankEstado = []
      let at
      let qt
      let lucro = 0
      const estadoIds = Object.keys(groupedByEstado)
      for (let i = 0; i < estadoIds.length; i++) {
        const estadoId = estadoIds[i]
        at = groupedByEstado[estadoId][0].estado_nome
        qt = groupedByEstado[estadoId].length
        // eslint-disable-next-line no-loop-func
        groupedByEstado[estadoId].map(item => {
          lucro = (parseFloat(item.valor_total_seguradora) - parseFloat(item.valor_total_aprovado)) + parseFloat(lucro)
        })
        rankEstado.push({
          nome: at,
          quantidade: qt,
          valor: lucro,
        })
        lucro = 0
      }
      const sortedArray = _.orderBy(rankEstado, 'valor', 'desc')
      this.rankEstado = sortedArray
    },
    async exportFile() {
      const file = await this.getDataToExported()
      if (file) {
        saveAs(file)
      }
    },
    setData() {
      const { items } = this
      const data = {
        total_reincidencia: 0,
        parcelas: 0,
        realizado: 0,
        tat: 0,
        cmr: 0,
        cmg: 0,
        pt: 0,
        rec: 0,
        lmi: 0,
        total_troca: 0,
        total_reparo: 0,
      }
      let valorTotalSeguradora6 = 0
      let valorTotalSeguradora9 = 0
      let valorTotalPago = 0
      let qtS6 = 0
      let qtS9 = 0
      let qts13 = 0
      let somaQDA = 0
      let qtReincidencia = 0
      const qtReclamacao = 0
      let somaIS = 0
      if (items) {
        data.parcelas = items.length
        items.map(item => {
          if (item.tipo_atedimento === 'REINCIDÊNCIA') {
            qtReincidencia += 1
          }
          somaQDA += item.dias_em_aberto
          valorTotalPago += parseFloat(item.valor_total_aprovado)
          if (item.produto_valor) {
            somaIS += parseFloat(item.produto_valor)
          }
          if (item.status_os_id === 6) {
            qtS6 += 1
            if (item.valor_total_seguradora) {
              valorTotalSeguradora6 += parseFloat(item.valor_total_seguradora)
            }
          }
          if (item.status_os_id === 13) {
            qts13 += 1
          }

          if (item.status_os_id === 9) {
            qtS9 += 1
            if (item.valor_total_seguradora) {
              valorTotalSeguradora9 += parseFloat(item.valor_total_seguradora)
            }
          }

          const vCMG = parseFloat(valorTotalSeguradora6) + parseFloat(valorTotalSeguradora9)
          const tCMG = parseInt(qtS6) + parseInt(qtS9)
          const CMR = valorTotalSeguradora6 / qtS6
          const CMG = vCMG / tCMG
          const TAT = somaQDA / items.length
          const PT1 = (qtS9 / tCMG)
          const PT = PT1 * 100
          const REC = (qtReclamacao / items.length) * 100
          const LMI = (CMG / somaIS) * 100

          if (somaIS === 0) {
            data.lmi = 0
          } else {
            data.lmi = LMI
          }

          data.cmg = CMG
          data.cmr = CMR
          data.tat = TAT
          data.rec = REC
          data.pt = PT
          data.total_troca = qtS9
          data.total_reparo = qtS6
          data.total_reincidencia = qts13
          data.total_pago = valorTotalPago
          data.total_reincidencia = qtReincidencia
          data.total_seguradora = vCMG
          data.total_lucro = parseFloat(valorTotalSeguradora6) + parseFloat(valorTotalSeguradora9) - valorTotalPago
        })
      }
      data.realizado = parseFloat(data.realizado.toFixed(2))
      this.data = data
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    async enviarPlanilha() {
      const { blob: file, table } = await this.getXlsx()
      if (file) {
        const { credor, data_recebimento_final } = this.filtro
        const emailBody = new FormData()
        // const cc = [
        //   { name: 'rafhael', address: 'rafhaelgigalucas@gmail.com' },
        //   { name: 'Jairo', address: 'jairorodrigues.sousa@gmail.com ' },
        // ]

        const cc = [
          { name: 'Marcos', address: 'marcos@grupovoz.com.br' },
          { name: 'Joao', address: 'joao.santos@grupovoz.com.br' },
          { name: 'Nathila', address: 'nathila@grupovoz.com.br' },
          { name: 'Henrique', address: 'henrique@grupovoz.com.br' },
          { name: 'Hadassa', address: 'hadassa@grupovoz.com.br' },
          { name: 'Jairo', address: 'jairo@grupovoz.com.br' },
        ]

        const response = await api.get(`api/v1/credor_contatos/${credor.id}`)
        const { dados } = response.data
        const to = dados
          .filter(item => item.relatorios.some(relat => relat.title === 'Prestação de contas'))
          .map(item => ({
            name: item.nome,
            address: item.email,
          }))

        emailBody.append('cc', JSON.stringify(cc))
        emailBody.append('to', JSON.stringify(to))
        // eslint-disable-next-line camelcase
        emailBody.append('subject', `${credor.nome}, aqui estão seus recebimentos do dia ${data_recebimento_final}`)
        emailBody.append('date', data_recebimento_final)
        emailBody.append('planilha', file)
        emailBody.append('credor', credor.nome)
        emailBody.append('table', table)

        api.post(`${process.env.VUE_APP_ROOT_HUBLE}/repasse-planilha`, emailBody).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notificação',
              icon: 'BellIcon',
              text: '👋 Email Enviado',
              variant: 'success',
            },
          }, { position: 'top-center' })
        })
      }
    },
    async getDataToExported() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Relatório de Fechamento')
      const itemsPlan = this.items

      worksheet.columns = [
        { header: 'ID', key: 'id' },
        { header: 'STATUS', key: 'status' },
        { header: 'TIPO ATENDIMENTO', key: 'tipo_atendimento' },
        { header: 'ATENDENTE', key: 'atendente' },
        { header: 'DATA ABERTURA', key: 'data_abertura' },
        { header: 'DATA FECHAMENTO', key: 'data_fechamento' },
        { header: 'QDA', key: 'dias_em_aberto' },
        { header: 'SEGURADORA', key: 'seguradora' },
        { header: 'SINISTRO', key: 'sinistro' },
        { header: 'CLIENTE', key: 'cliente' },
        { header: 'PRODUTO', key: 'produto' },
        { header: 'IS', key: 'produto_valor' },
        { header: 'VL APROVADO SEG.', key: 'valor_total_seguradora' },
        { header: 'GASTO', key: 'valor_total_aprovado' },
        { header: 'LUCRO', key: 'valor_total_aprovado' },
        { header: 'REVENDA', key: 'revenda' },
        { header: 'LINHA', key: 'linha' },
        { header: 'MARCA', key: 'marca' },
        { header: 'CIDADE', key: 'cidade_nome' },
        { header: 'ESTADO', key: 'estado_nome' },
      ]

      itemsPlan.map(item => {
        worksheet.addRow([
          item.id,
          item.status,
          item.tipo_atendimento,
          item.atendente,
          item.data_abertura ? this.formatTimezone(item.data_abertura) : null,
          item.data_fechamento ? this.formatTimezone(item.data_fechamento) : null,
          item.dias_em_aberto ? item.dias_em_aberto : null,
          item.seguradora,
          item.sinistro,
          item.cliente,
          item.produto,
          item.produto_valor ? this.moedaBR(item.produto_valor) : null,
          item.valor_total_seguradora ? this.moedaBR(item.valor_total_seguradora) : null,
          item.valor_total_aprovado ? this.moedaBR(item.valor_total_aprovado) : null,
          item.valor_total_aprovado ? this.calcLucro(item.valor_total_seguradora, item.valor_total_aprovado) : null,
          item.revenda,
          item.linha,
          item.marca,
          item.cidade_nome,
          item.estado_nome,
        ])
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório de Fechamento', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
    calcLucro(valor1, valor2) {
      return this.moedaBR(parseFloat(valor1) - parseFloat(valor2))
    },
    dataAtual() {
      // Obter a data atual
      const dataAtual = new Date()

      // Obter os componentes da data atual
      const ano = dataAtual.getFullYear()
      const mes = (`0${dataAtual.getMonth() + 1}`).slice(-2) // Adicionar 1 ao mês, pois os meses em JavaScript são baseados em zero
      const dia = (`0${dataAtual.getDate()}`).slice(-2)

      // Formatar a data no formato desejado
      const dataFormatada = `${ano}-${mes}-${dia}`
      return dataFormatada
    },
  },
}
</script>
